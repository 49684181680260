* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
  overflow: hidden;
}



.container {
  margin: 0.8% auto;
  width: 800px;
  padding: 0.8% 1.6%;
  max-width: 100%;
  display: grid;
}

.msg {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 10px #000;
  padding: 10px;
}


.wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}


button {
  background: #282c34;
  color: #fff;
  border: none;
  padding: 15px;
  border-radius: 5px;
  font-size: 1.2em;
  cursor: pointer;
  margin: 5px auto;
  width: 400px;
  max-width: 100%;
}

button:nth-child(2) {
  background: #fff;
  color: #282c34;
}

p {
  font-size: 16px;
  margin: 5px;
}

section {
  margin-bottom: 20px !important;
}

body {
  background: #fafafa;
}

.titleContext {
  font-size: 1.5em;
  font-weight: bold;
  text-align: left;
}